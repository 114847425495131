import { Controller } from '@hotwired/stimulus'
import { transition } from 'tailwindcss-stimulus-components/src/transition'

export default class extends Controller {
  static outlets = ['toggleable']
  static values = {
    open: { type: Boolean, default: false },
    switch: { type: Boolean, default: false }
  }

  declare readonly toggleableOutletElements: HTMLInputElement[]
  declare openValue: boolean
  declare switchValue: boolean

  toggle (): void {
    this.openValue = !this.openValue
    this.animate()
  }

  animate (): void {
    this.toggleableOutletElements.forEach(target => {
      if (this.switchValue) {
        target.classList.toggle('hidden')
      } else {
        transition(target, this.openValue)
      }
    })
  }
}
