import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  visit (event: Event): void {
    event.preventDefault()

    const [urlWithoutAnchor, anchor] = (this.element.getAttribute('href') as string).split('#')
    const params = new URLSearchParams(window.location.search)

    // Remove the `page` parameter
    params.delete('page')

    let newUrl = urlWithoutAnchor
    if (params.toString() !== '') newUrl += `?${params.toString()}`
    if (anchor !== undefined && anchor !== '') newUrl += `#${anchor}`

    Turbo.visit(newUrl, { frame: 'domains-show' })
    Turbo.navigator.history.replace(new URL(newUrl, window.location.origin))
  }
}
