import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'bulkActions', 'count', 'selectCurrent', 'selectAll', 'fakeSelected']

  static values = {
    count: Number
  }

  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly bulkActionsTarget: HTMLDivElement
  declare readonly countTarget: HTMLElement
  declare readonly selectCurrentTarget: HTMLInputElement
  declare readonly selectAllTarget: HTMLInputElement
  declare readonly fakeSelectedTarget: HTMLInputElement
  declare readonly hasSelectAllTarget: boolean
  declare readonly hasFakeSelectedTarget: boolean
  declare countValue: number

  connect (): void {
    const observer = new MutationObserver(() => {
      this.updateCount()
    })

    observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }

  selectCurrent (): void {
    const { checked } = this.selectCurrentTarget

    this.toggleCheckboxTargets(checked)
    this.toggleCheckboxDisabledTargets(false)

    this.selectFakeSelected(checked)

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = false
    }
    this.countValue = checked ? this.checkboxTargets.length : 0
  }

  selectAll ({ params: { count } }: { params: { count: number } }): void {
    const { checked } = this.selectAllTarget

    this.toggleCheckboxTargets(checked)
    this.toggleCheckboxDisabledTargets(checked)
    this.selectFakeSelected(checked)
    this.selectCurrentTarget.checked = false
    this.countValue = checked ? count : 0
  }

  deselectAll (): void {
    this.toggleCheckboxTargets(false)
    this.selectFakeSelected(false)
    this.selectCurrentTarget.checked = false

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = false
      this.toggleCheckboxDisabledTargets(false)
    }

    this.countValue = 0
  }

  select ({ target }: { target: HTMLInputElement }): void {
    target.checked ? this.countValue++ : this.countValue--
  }

  private countValueChanged (): void {
    if (this.countValue > 0) {
      this.show(this.bulkActionsTarget)
    } else {
      this.hide(this.bulkActionsTarget)
    }
    this.countTarget.innerText = this.countValue.toString()
  }

  private toggleCheckboxTargets (checked: boolean): void {
    this.checkboxTargets.forEach((checkbox) => { checkbox.checked = checked })
  }

  private toggleCheckboxDisabledTargets (disabled: boolean): void {
    this.checkboxTargets.forEach((checkbox) => { checkbox.disabled = disabled })
  }

  private selectFakeSelected (checked: boolean): void {
    if (this.hasFakeSelectedTarget) {
      this.fakeSelectedTarget.checked = checked
    }
  }

  private show (element: HTMLElement): void {
    element.classList.remove('hidden')
  }

  private hide (element: HTMLElement): void {
    element.classList.add('hidden')
  }

  private updateCount (): void {
    if (this.countValue > 0) {
      if (this.hasSelectAllTarget && this.selectAllTarget.checked) {
        this.toggleCheckboxTargets(true)
      } else {
        this.countValue = this.checkboxTargets.filter(checkbox => checkbox.checked).length
      }
    }
  }
}
